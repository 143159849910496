<template>
    <v-container fluid>
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-subheader class="headline">{{ $t('deals') }}</v-subheader>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="4" class="text-right">
                            <v-btn :to="{name: 'deal.create'}" class="white--text" color="indigo">
                                {{$t('create') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col class="py-0" cols="12" sm="12">
                            <ValidationProvider ref="business_process" rules="required"
                                                v-slot="{ errors, valid }">
                                <v-select v-model="business_process"
                                          :items="businessProcessItems" :error-messages="errors"
                                          @change="changeBusinessProcesses()"
                                          :error="!valid"
                                          item-text="name" item-value="id"
                                          :label="$t('business_process')"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :disabled="loading"
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                          clearable></v-select>
                            </ValidationProvider>
                        </v-col>


                    </v-row>
                </v-card-text>
                <v-card-actions class="px-4 py-4">
                    <v-row>
                        <v-col cols="12" sm="3" md="3">
                            <v-btn icon>
                                <v-icon @click="$router.push({name: 'deal'})">
                                    mdi-view-headline
                                </v-icon>
                            </v-btn>
                            <v-btn icon @click="$router.push({name: 'deal.kanban'})">
                                <v-icon>
                                    mdi-format-columns
                                </v-icon>
                            </v-btn>
                            <v-btn @click="setSubordinates()" :loading="loading">
                                {{ subordinates === false ? $t('subordinates') : $t('my_tasks')}}
                            </v-btn>
                        </v-col>
                        <v-col v-if="false" cols="12" sm="9" md="9"
                               class="d-flex justify-center justify-sm-end align-self-center">
                            <v-btn @click="searchTask" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                   color="primary">{{ $t('search') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </ValidationObserver>
        <div class="kanban">
            <div
                 v-for="(item, index) in deal_statusItems"
                   :key="item.slug + '_' + index">
                <div>{{item.name}} - {{ item.deal_total }}</div>
                <v-divider class="my-1 mb-5 my_hr" :style="'border-color:'+item.color"></v-divider>

                <draggable :disabled="!item.kanban_disabled" class="list-group"
                           :list="item.deals"
                           group="people"
                           @change="changeDrag($event,item.id)"
                           @start="startDrag(item,index)"
                           @end="endDrag"
                >

                    <div
                        class="list-group-item"
                        v-for="(element, index) in item.deals"
                        :key="element.number + '_' + index"
                    >
                        <div class="kanban_task" :style="'border-left: 3px solid' +  item.color">
                            <div class="body-2 kanban_link" @click="showDeal(element)">{{ element.number }}. {{ element.title }}</div>
                            <v-col cols="12" class="text-right">
                                <v-avatar size="36" class="border_blue my-0 py-0">
                                    <img v-if="element.admin.photo !== '/img/avatar.png'" :src="element.admin.photo" :alt="element.admin.name">
                                    <v-icon v-else color="primary" size="22">
                                        ${{'settingsIcon'}}
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                        </div>
                    </div>

                </draggable>

            </div>
        </div>


    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"
    import draggable from "vuedraggable";
    import {ValidationObserver, ValidationProvider} from "vee-validate";

    export default {
        name: "CalendarKanban",
        order: 1,
        components: {
            draggable,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                loading: false,
                drag: false,
                deal_statusItems: [],
                task_status: null,
                page: 1,
                get_scroll: 1,
                perPage: 15,
                subordinates: false,
                business_process: null,
                businessProcessItems: [],
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'BusinessProcessId']),
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },

        async mounted() {
            await this.getBusinessProcesses();
            this.business_process = this.BusinessProcessId
            await this.getDealStatus()
            await this.getDeals()
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.get_scroll === 1) {
                    this.page++;
                    this.getDeals();
                }
            };

        },


        methods: {
            startDrag(item, index){
                // for (const value of this.deal_statusItems) {
                //     if (item.where_move.indexOf(value.id) !== -1) {
                //             value.kanban_disabled =  true;
                //     }
                //     else {
                //         value.kanban_disabled = false
                //     }
                // }
            },
            endDrag(){
                for (const value of this.deal_statusItems) {
                    value.kanban_disabled = true
                }
            },
            changeDrag(evt, deal_status) {
                if(evt && evt.added && evt.added.element && evt.added.element.uuid){
                    this.editStatus(evt.added.element.uuid, deal_status)
                }
            },

            searchTask(){
                this.page = 1;
                this.getCases();

            },
            async getDealStatus() {
                this.loading = true
                let params = {}
                params.business_process = this.business_process;
                await this.$http
                    .get("admin/deal_status", {
                        params: params,
                    })
                    .then(res => {
                        this.deal_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.deal_statusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getDeals() {
                this.get_scroll = 0;
                for (const value of this.deal_statusItems) {
                    this.loading = true;
                    let params = {};
                    params.sortBy = 'id';
                    params.sortDir = 'desc';
                    params.perPage = this.perPage;
                    params.page = this.page
                    params.business_process = this.business_process;
                    params.deal_status = value.id;

                    let add_filter = '';
                    if(this.subordinates === true){
                        add_filter = '?fltr[all][eq]=1';
                    }

                    await this.$http
                        .get("admin/deal"+add_filter, {
                            params: params,
                        })
                        .then(res => {
                            if(this.page === 1){
                                this.get_scroll = 1;
                                value.deals = res.body.data
                                value.deal_total = res.body.meta.total;
                            }
                            else {
                                for (const item of res.body.data) {
                                    this.get_scroll = 1;
                                    value.deals.push(item);
                                }
                            }
                        })
                        .catch(err => {
                            this.deals = []
                            this.$toastr.error(this.$t('failed_to_get_list_deals'))
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async editStatus(deal_uuid, deal_status) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])

                }
                formData.append('deal_status_id', deal_status)
                formData.append('deal_uuid', deal_uuid)

                await this.$http
                    .put(`admin/set/deal/status`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            showDeal(item) {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        id: item.uuid
                    }
                })
            },

            setSubordinates(){
                for (const value of this.deal_statusItems) {
                    value.deals = []
                }
                this.page = 1;
                if(this.subordinates === false){
                    this.subordinates = true;
                }
                else{
                    this.subordinates = false;
                }
                this.getDeals();
            },
            async getBusinessProcesses() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/business_process", {
                        params: params,
                    })
                    .then(res => {
                        this.businessProcessItems = res.body.data
                    })
                    .catch(err => {
                        this.businessProcessItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },

            async changeBusinessProcesses(){
                await this.getDealStatus()
                await this.getDeals()
            },

        }
    }
</script>

<style>
.kanban{
    flex-grow: 1;display:flex;margin-bottom: 1rem; overflow: auto
}
.kanban > div{
    flex-shrink: 0;width: 320px;padding: 10px 10px 0;overflow-y: auto;margin-right: 20px;
}
.kanban_task{
    -webkit-box-shadow: 0 0 2px 0 red;
    box-shadow: 0 0 2px 0 #b3b3b3,0 2px 2px 0 #b3b3b3;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 12px 16px;
    position: relative;
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    -o-transition: box-shadow .3s;
    cursor: all-scroll;
    min-height: 40px;
    border-radius: 3px;

}
.kanban_link{
    cursor: pointer;
}
.kanban_link:hover{
    color: #64B5F6;
}
.sortable-ghost{
    min-height: 40px;
    opacity: 0.5;
    z-index: 100;
    margin-bottom: 15px;
    border: dashed 2px;
    -webkit-box-shadow: none;
    box-shadow:none;
    border-radius:0;
    -webkit-box-sizing: unset;
    box-sizing: unset;
    padding:0;
}
.sortable-ghost div{
    -webkit-box-shadow: none;
    box-shadow:none;
    min-height: 40px;
    border: 0 !important;

}
.sortable-ghost div div{
    visibility: hidden;
}
</style>
